<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <span>
        <!-- tmp hide set favorite feature
        <span v-if="projId" class="isLink me-1">
            <span v-if="isFavourite && isFavourite === true">
                <Popper class="popperDark" arrow hover content="Click to remove from favorite">
                    <fa icon="star" size="lg" color="orange" @click.prevent="updFavorite(projId, false)" />
                </Popper>
            </span>
            <span v-else>
                <Popper class="popperDark" arrow hover content="Click to add to favorite">
                    <fa icon="star" size="lg" @click.prevent="updFavorite(projId, true)" class="text-grey" />
                </Popper>
                <fa icon="star" size="lg" class="text-grey" />
            </span>
        </span> -->

        <span v-if="projId" class="me-1">
            <span v-if="isFavourite && isFavourite === true">
                <fa icon="star" size="lg" color="orange" />
            </span>
            <span v-else>
                <fa icon="star" size="lg" class="text-grey" />
            </span>
        </span>

        <span v-if="mode === 'SIMPLE'" class="me-1">
            <Popper class="popperDark" arrow hover content="SIMPLE - Sign with valid email only">
                <fa :icon="['far', 'circle']" size="lg" />
            </Popper>
        </span>
        <span v-if="mode === 'KYC'" class="me-1">
            <Popper class="popperDark" arrow hover content="KYC - Require to verify identity. You may assign signing sequence">
                <fa icon="circle-half-stroke" size="lg"/>
            </Popper>
        </span>
        <span v-if="mode === 'STRICT'" class="me-1">
            <Popper class="popperDark" arrow hover content="STRICT - You can review the signing process manually, request for footage of signing, upload hardcopy of signed documents later">
                <fa icon="circle"  size="lg"/>
            </Popper>
        </span>

        <!-- Enerson: temp hide 
        <span v-if="permission !== null" class="isTooltipsLink me-1">
            <span v-if="permission === 'SHARED'" >
                <Popper class="popperDark" arrow hover content="Documents within the same folder are shared among signees">
                    <fa icon="share-square" size="lg" class="text-grey"/>
                </Popper>
            </span>
            <span v-else>
                <Popper class="popperDark" arrow hover content="Documents with permission controlled for every signee">
                    <fa icon="mask" size="lg" class="text-grey"/>
                </Popper>
            </span>
        </span> -->
        <span v-if="isEdit === true || isEdit === 'true'" class="me-1">
            <span v-if="projTitle && projTitle.length > 0">
                <span v-if="shred === 'STAGING'" class="isLink" data-bs-toggle="modal" data-bs-target="#mdlEditFolder">
                    <Popper class="popperDark" arrow hover :content="'Mark as shred'">
                        <span class="fw-bold text-decoration-line-through">{{projTitle}}</span>
                    </Popper>
                </span>
                <span v-else class="fw-bold isLink" data-bs-toggle="modal" data-bs-target="#mdlEditFolder">
                    {{projTitle}}
                </span>
            </span>
            <span v-else>-</span>
        </span> 
        <span v-else class="me-1">
            <span v-if="projTitle && projTitle.length > 0">
                <span v-if="shred === 'STAGING'">
                    <Popper class="popperDark" arrow hover content="Unmark as shred">
                        <span class="fw-bold text-decoration-line-through">{{projTitle}}</span>
                    </Popper>
                </span>
                <span v-else class="fw-bold">{{ projTitle }}</span>
            </span>
            <span v-else>-</span>
        </span> 

        <Popper class="popperDark" arrow hover content="Document">
            <span class="badge bg-grey isTooltipsLabel me-1"><fa icon="file" /> {{ totalDoc }}</span>
        </Popper>

        <Popper class="popperDark" arrow hover content="Signee">
            <span class="badge bg-grey isTooltipsLabel me-1"><fa icon="user" /> {{ totalSignee }}</span>
        </Popper>

        <ProjStatus :status="status" :shred="shred" :showDesc="true" />

        <ProjPrivacy :value="privacy" class="ms-1" :showDesc="true" />

        <span data-bs-toggle="modal" data-bs-target="#mdlEditFolder" class="text-primary isLink float-end">
            <Popper class="popperDark" arrow hover content="Edit folder">
                <fa icon="gear" size="lg" />
            </Popper>
        </span>
    </span>

    <!-- Modal: Edit folder --> 
    <div class="modal fade" id="mdlEditFolder" tabindex="-1" aria-labelledby="EditProj" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Folder</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12 mb-2">
                            <div class="form-floating">
                                <input type="text" class="form-control" v-model="newProjName" id="folderName" placeholder="Folder Name" maxlength="100">
                                <label for="folderName">Folder Name</label>
                            </div>
                        </div>

                        <div class="col-12"><hr style="color: var(--accent-color)"/></div>

                        <div class="col-12 mt-2 mb-2">Privacy</div>
                        <div class="col-12 mb-2">
                            <table>
                                <tr>
                                    <td width="30px"><input type="radio" v-model="newProjPrivacy" value="PUBLIC" /></td>
                                    <td><ProjPrivacy value="PUBLIC" /> Anyone with the link (default).</td>
                                </tr>
                                <tr>
                                    <td><input type="radio" v-model="newProjPrivacy" value="PRIVATE" /></td>
                                    <td><ProjPrivacy value="PRIVATE" /> Restricted: Only people added can access.</td>
                                </tr>
                                <!-- <tr>
                                    <td><input type="radio" v-model="newProjPrivacy" value="CONFIDENTIAL" /></td>
                                    <td class="pe-1"><ProjPrivacy value="CONFIDENTIAL" /> Only disclose document name and timestamp.</td>
                                </tr> -->
                            </table>
                        </div>
                    </div>

                </div>
                <div class="modal-footer" style="justify-content: space-between;">
                    <button v-if="shredPolicy === 'NO_SHRED' && shred === 'STAGING'" class="btn btn-outline-secondary" data-bs-toggle="modal" data-bs-target="#mdlUnmarkDelFolder">
                        <i class="fa-solid fa-trash-xmark me-2"></i>Unmark as Shred
                    </button>

                    <button v-else-if="shredPolicy === 'NO_SHRED' && shred === 'PENDING'" class="btn btn-outline-secondary" data-bs-toggle="modal" data-bs-target="#mdlCancelDelFolder">
                        <i class="fa-solid fa-trash-slash me-2"></i>Unshred
                    </button>

                    <button v-if="shredPolicy !== 'NO_SHRED'" class="btn btn-outline-secondary" data-bs-toggle="modal" data-bs-target="#mdlDelFolder">
                        <i class="fa-solid fa-trash me-2"></i>Shred
                    </button>
                    
                    <div>
                        <button type="button" class="btn btn-outline-secondary me-2" data-bs-dismiss="modal">
                            <i class="fa-solid fa-xmark me-2"></i>Close
                        </button>
                            
                        <button type="button" class="btn btn-secondary" @click="updProj" data-bs-dismiss="modal">
                            <i class="fa-solid fa-floppy-disk me-2"></i>Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Modal: confirmation delete folder --> 
    <div class="modal fade" id="mdlDelFolder" tabindex="-1" aria-labelledby="DelProj" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Shred folder</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">

                    <div v-if="shredPolicy === 'IMMEDIATE'">
                        <div class="mb-2">This folder and its documents will be immediately shredded and unrecoverable.</div>
                        <div>Confirm to shred <strong>{{newProjName}}</strong> folder?</div>
                    </div>

                    <div v-if="shredPolicy === 'WITHOUT_APPROVAL'">
                        <div class="mb-2">This folder will mark as shred until you circulate it, after which it will be shredded immediately.</div>
                        <div>Confirm to mark <strong>{{newProjName}}</strong> folder as shred?</div>
                    </div>

                    <div v-if="shredPolicy === 'WITH_APPROVAL'">
                        <table>
                            <tr>
                                <td colspan="2">
                                    <div class="mb-2">This folder will mark as shred until you circulate it.</div>
                                    <div class="mb-2 fw-bold">During circulating:</div>
                                </td>
                            </tr>
                            <tr>
                                <td class="pb-3"><i class="fa-solid fa-trash fa-3x me-3"></i></td>
                                <td class="pb-3">It will be shredded after receving approval from all signees who have signed previously.</td>
                            </tr>
                            <tr>
                                <td class="pb-3"><i class="fa-solid fa-calendar-days fa-3x me-3"></i></td>
                                <td class="pb-3">It will be kept for {{ objLSPlan.dayToDeleteShreddedSealedDocument }} days, and during this period the document is still accessible by signees.</td>
                            </tr>
                        </table>
                        <div>Confirm to mark <strong>{{newProjName}}</strong> folder as shred?</div>
                    </div>

                </div>
                <div class="modal-footer" style="justify-content: space-between">
                    <button v-if="shredPolicy === 'IMMEDIATE'" type="button" class="btn btn-secondary" @click="delProj" data-bs-dismiss="modal">
                        <fa icon="trash" class="me-2" />Shred
                    </button>
                    <button v-if="shredPolicy === 'WITH_APPROVAL' || shredPolicy === 'WITHOUT_APPROVAL'" type="button" class="btn btn-secondary" @click="delProj" data-bs-dismiss="modal">
                        <fa icon="trash" class="me-2" />Mark as Shred
                    </button>

                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Close
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- Modal: unmark shred folder --> 
    <div class="modal fade" id="mdlUnmarkDelFolder" tabindex="-1" aria-labelledby="unmarkDelFolder" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Unmark shred folder</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div>Folder <strong>{{ newProjName }}</strong> was marked for shredding previously, confirm to unmark?</div>
                </div>
                <div class="modal-footer" style="justify-content: space-between">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <i class="fa-solid fa-xmark me-2"></i>Close
                    </button>
                    <button type="button" class="btn btn-secondary" @click="unmarkDelFolder" data-bs-dismiss="modal">
                        <i class="fa-solid fa-trash-xmark me-2"></i>Unmark
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End-->

    <!-- Modal: cancel shred / unshred folder --> 
    <div class="modal fade" id="mdlCancelDelFolder" tabindex="-1" aria-labelledby="cancelDelFolder" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Unshred folder</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div>Are you sure you want to cancel the shredding of the <strong>{{ newProjName }}</strong> folder?</div>
                </div>
                <div class="modal-footer" style="justify-content: space-between">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <i class="fa-solid fa-xmark me-2"></i>Close
                    </button>
                    <button type="button" class="btn btn-secondary" @click="unshredFolder" data-bs-dismiss="modal">
                        <i class="fa-solid fa-trash-slash me-2"></i>Unshred
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End-->

</template>

<script>
import {ref, onMounted, inject, computed} from 'vue'
import Popper from 'vue3-popper'
import ProjPrivacy from '@/components/ProjPrivacy.vue'
import ProjStatus from '@/components/ProjStatus.vue'
import Alert from '@/components/Alert.vue'
import funcs from '@/functions/function'
import { useStore } from '@/stores/store'
import { useRouter } from 'vue-router'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

export default {
    name: 'ProjTitle',
    components: { Alert, Popper, ProjPrivacy, ProjStatus, VueDatePicker },
    props: [ 'projId', 'mode', 'permission', 'projTitle', 'totalSignee', 'totalDoc', 'status', 'privacy', 'isFavourite', 'isEdit', 'shred', 'shredPolicy'],
    emits: ['refreshProj'],
    setup (props, {emit}) {
        const alert = ref([])
        const axios = inject('axios')
        const func = funcs
        const store = useStore()
        const router = useRouter()

        const isSignOn = ref(false) // detect if from signon Entity

        const projId = ref(props.projId)
        const projTitle = ref(props.projTitle)
        const projStatus = ref(props.status)
        const privacy = ref(props.privacy)
        const isFavourite = ref(props.isFavourite)
        const isEdit = ref(props.isEdit)

        const newProjName = ref(props.projTitle)
        const newProjPrivacy = ref('PUBLIC')

        const refreshProj = ref(null)
        const objLSProj = ref({})
        const objLSPlan = ref({})

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        const unmarkDelFolder = () => {
            axios.put( '/signon/' + projId.value + '/cancelShred', {})
            .then((res) => {
                console.info('- - unmarkDelFolder', JSON.stringify(res))

                if (res.data.status === 1001) {
                    router.push({ path: '/auth/login', query: {e: 'ns'} })

                } else  if (res.data.status === 1) {

                    /* alert.value.push({
                        class: "success",
                        title: "SUCCESS",
                        message: "The folder '" + newProjName.value + "' has been unmarked for shredding."
                    }) */

                    emit('refreshProj', projId.value)

                } else {
                    func.addLog('projTitle', 'unmarkDelFolder', res)

                    if (res && res.data !== null && res.data !== undefined) {

                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: func.getError(res.data)
                        })
                    }
                }

            })
            .catch((error) => {
                func.addLog('projTitle', 'unmarkDelFolder - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

            })
        }

        const unshredFolder = () => {
            axios.put( '/signon/' + projId.value + '/cancelShred', {})
            .then((res) => {
                console.info('- - unshredFolder', JSON.stringify(res))
                if (res.data.status === 1001) {
                    router.push({ path: '/auth/login', query: {e: 'ns'} })

                } else  if (res.data.status === 1) {

                    /* alert.value.push({
                        class: "success",
                        title: "SUCCESS",
                        message: "The shredding of the folder '" + newProjName.value + "' has been cancelled."
                    }) */

                    emit('refreshProj', projId.value)

                } else {
                    func.addLog('projTitle', 'unshredFolder', res)

                    if (res && res.data !== null && res.data !== undefined) {

                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: func.getError(res.data)
                        })
                    }
                }

            })
            .catch((error) => {
                func.addLog('upload', 'cancelDelDoc - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

            })
        }

        const delProj = () => {
            axios.delete( '/signon/' + projId.value)
                .then((res) => {
                    // console.info('del res', res.data.status, JSON.stringify(res))
                    if (res.data.status === 1) {
                        
                        if (res.data.description.shred === 'STAGING') {
                            alert.value.push({
                                class: "success",
                                title: "SUCCESS",
                                message: "Folder is marked as shred."
                            })

                            emit('refreshProj', projId.value)

                        } else {
                            alert.value.push({
                                class: "success",
                                title: "SUCCESS",
                                message: "Folder is shredded."
                            })

                            router.push({ path: '/project' })
                        }
                            

                    } else {
                        func.addLog('projTitle', 'delProj', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                })
                .catch((error) => {
                    func.addLog('projTitle', 'delProj - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                })

        }

        const updFavorite = (id, val) => {
            const p = { isFavourite: val }

            axios.put( '/signon/' + id, p)
                .then((res) => {
                    if (res.data.status === 1) {
                        isFavourite.value = val

                    } else {
                        func.addLog('projTitle', 'updFavorite', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                })
                .catch((error) => {
                    func.addLog('projTitle', 'updFavorite - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })
        }

        const updProj = () => {
            // console.info('updproj1', projTitle.value, newProjName.value)
            // console.info('updproj2', privacy.value, newProjPrivacy.value)

            if (projTitle.value !== newProjName.value || privacy.value !== newProjPrivacy.value) {
                const p = { 
                    folderName: newProjName.value, 
                    privacy: newProjPrivacy.value
                }

                axios.put( '/signon/' + projId.value, p)
                    .then((res) => {

                        if (res.data.status === 1) {
                            projTitle.value = newProjName.value

                            objLSProj.value = { id: projId.value, name: newProjName.value, status: projStatus.value}
                            localStorage.setItem('project', funcs.encodeJsonToStrSimple(objLSProj.value))

                            alert.value.push({
                                class: "success",
                                title: "SUCCESS",
                                message: "Folder is updated successfully."
                            })

                            emit('refreshProj', projId.value)

                        } else {
                            func.addLog('projTitle', 'updProj', res)

                            if (res && res.data !== null && res.data !== undefined) {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })

                            }
                        }
                    })
                    .catch((error) => {
                        func.addLog('projTitle', 'updProjName - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    })

            }
        }
      

        onMounted(() => {

            // console.info('ProjTitle onMounted', projTitle.value, isEdit.value)
            if (privacy.value !== null && privacy.value !== undefined && privacy.value !== '') {
                newProjPrivacy.value = privacy.value
            }

            if (func.isEmptyObject(localStorage.getItem('entity')) === false) {
                let objLSEntity = func.decodeStrToJsonSimple(localStorage.getItem('entity'))
                if ( objLSEntity.entityId === '1') {
                    isSignOn.value = true
                }
            }

            if (func.isEmptyObject(localStorage.getItem('planInfo')) === false) {
                objLSPlan.value = func.decodeStrToJsonSimple(localStorage.getItem('planInfo'))

            }

        })

        return { 
            store, axios, alert, closeAlert, 
            projId, projTitle, projStatus, privacy, isFavourite, isEdit, 
            newProjName, newProjPrivacy, updFavorite,
            refreshProj, isSignOn, updProj, delProj, unmarkDelFolder, unshredFolder, objLSPlan
        }
    }
}
</script>

<style>

</style>